/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../theme"
import { FC } from "react"
import GatsbyImage, { FluidObject, GatsbyImageProps } from "gatsby-image/index"

interface IRatio {
  mq?: string
  x: number
  y: number
}

interface IProps {
  width: string
  ratio: IRatio | IRatio[]
  fluid?: FluidObject
  rootStyle?: SerializedStyles
  imgWrapperStyle?: SerializedStyles
  imgStyle?: GatsbyImageProps["imgStyle"]
  prepend?: any
  append?: any
  otherGatsbyImageProps?: Omit<GatsbyImageProps, "imgStyle" | "fluid" | "css">
}

const makeStyles = (theme: IAppTheme, props: IProps) => {
  const generatePaddingArray = item =>
    item.mq
      ? css`
          ${item.mq} {
            padding-top: calc(${item.y / item.x} * 100%);
          }
        `
      : css`
          padding-top: calc(${item.y / item.x} * 100%);
        `

  return {
    root: css`
      width: ${props.width};
    `,
    imageWrapper: css`
      height: 0;
      overflow: hidden;
      background: white;
      position: relative;
    `,
    imageWrapperPaddingTopArray: Array.isArray(props.ratio)
      ? props.ratio.map(generatePaddingArray)
      : generatePaddingArray(props.ratio),

    // use normal object for this one
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  }
}

const RatioImage: FC<IProps> = props => {
  const styles = makeStyles(useTheme(), props)
  const {
    append,
    prepend,
    imgStyle,
    rootStyle,
    imgWrapperStyle,
    fluid,
    otherGatsbyImageProps,
  } = props

  return (
    <div css={[styles.root, rootStyle]}>
      {prepend}
      {fluid && (
        <GatsbyImage
          css={[
            styles.imageWrapper,
            ...(Array.isArray(styles.imageWrapperPaddingTopArray)
              ? styles.imageWrapperPaddingTopArray
              : [styles.imageWrapperPaddingTopArray]),
            imgWrapperStyle,
          ]}
          imgStyle={{ ...styles.image, ...imgStyle }}
          fluid={fluid}
          {...otherGatsbyImageProps}
        />
      )}
      {append}
    </div>
  )
}

export default RatioImage
